import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { Box, SvgIcon, Table, TableBody, TableContainer, TableHead, TableRow, useTheme } from '@mui/material';
import { AscIcon, DescIcon } from 'assets/icons';
import { BigNumber } from 'bignumber.js';
import { ITableHeaders, TSortOrder } from 'common/defines/constants';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import ReactECharts from 'echarts-for-react';
import { isArray, isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { clientSelector } from 'store/slices/clientSlice';
import TableCellHeaderSummary from './components/TableCellHeaderSummary';
import TableCellSummary from './components/TableCellSummary';
import StandExtendChartModal from './StandExtendChartModal';

enum TableKeyEnum {
  LEVEL = 'Level',
  BLANK_SPOT = 'Blank spot',
  TREE = 'Tree',
}

interface IRow {
  level: string;
  blankSpot: number;
  tree: number;
}

const StandCountTable = () => {
  const [isShowExtendChart, setIsShowExtendChart] = useState<boolean>(false);
  const { allLocationCropType } = useAppSelector(clientSelector);
  const [optionCols, setOptionCols] = useState<ITableHeaders<TableKeyEnum>[]>([]);
  const [sortInfo, setSortInfo] = useState<{ key: TableKeyEnum; sortOrder: TSortOrder }>({
    key: TableKeyEnum.LEVEL,
    sortOrder: null,
  });
  const theme = useTheme();
  const { t } = useTranslation();

  const listNameLevel = useMemo(() => {
    const options = [] as any;
    if (!allLocationCropType) return;
    allLocationCropType.forEach((item) => {
      const isDisplayNameChart = item.standCountAnalysisSummary.some((item: any) => item.value);
      isDisplayNameChart && options.push(item.name);
    });
    return options;
  }, [allLocationCropType]);

  const isShowExtendIcon = listNameLevel.length > 20;

  const listStandsPerHectar = useMemo(() => {
    const options = [] as any;
    if (!allLocationCropType) return;
    allLocationCropType.forEach((item) => {
      if (item.totalTree && item.area) {
        const standsPerHa = new BigNumber(item.totalTree / item.area).toFixed(0);
        options.push({ value: standsPerHa, itemStyle: { color: '#10B981' } });
      }
      return options;
    });
    return options;
  }, [allLocationCropType]);

  const option = {
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'shadow',
      },
      backgroundColor: theme.palette.mode === 'dark' ? '#24292e' : '#fff',
      textStyle: {
        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
      },
    },
    xAxis: {
      type: 'category',
      data: listNameLevel,
      axisLabel: {
        rotate: 90,
        hideOverlap: false,
        overflow: 'truncate',
        width: 60,
        fontSize: 10,
      },
    },

    yAxis: {
      nameLocation: 'middle',
      name: t('mapView.stands_per_hectare'),
      nameTextStyle: {
        verticalAlign: 'middle',
        fontSize: 13,
        fontWeight: '500',
        fontFamily: 'Barlow',
      },
      axisLabel: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      max: function (value: any) {
        return value.max + 40;
      },
      axisLine: {
        show: true,
        symbol: ['none', 'arrow'],
        symbolSize: [7, 10],
      },
    },
    backgroundColor: theme.palette.mode === 'dark' ? '#24292e' : '#fff',
    series: [
      {
        data: listStandsPerHectar,
        type: 'bar',
        label: {
          show: !(listNameLevel.length > 20),
          position: 'top',
          color: '#10B981',
          fontSize: 8,
        },
        barWidth: listNameLevel.length > 10 ? Math.floor(300 / listNameLevel.length) + 'px' : '30px',
      },
    ],
  };

  const extendChartOption = {
    ...option,
    series: option.series.map((item: any) => ({
      ...item,
      barWidth: listNameLevel.length > 10 ? Math.floor(800 / listNameLevel.length) + 'px' : '40px',
      label: { ...item.label, show: true, fontSize: 10 },
    })),
  };

  const tableCropCoverage = useMemo(() => {
    if (allLocationCropType && allLocationCropType.length > 0) {
      const dataRows = allLocationCropType.map((row, i) => {
        return {
          level: row?.name,
          tree: row?.standCountAnalysisSummary.find((item: { title: string }) => item.title === 'Tree')?.value ?? 0,
          blankSpot:
            row?.standCountAnalysisSummary.find((item: { title: string }) => item.title === 'Blank spot')?.value ?? 0,
        } as IRow;
      });

      const sortFunction = (first: IRow, second: IRow) => {
        const { key, sortOrder } = sortInfo;
        if (key === TableKeyEnum.LEVEL) {
          return sortOrder === 'ASC'
            ? first.level.localeCompare(second.level)
            : second.level.localeCompare(first.level);
        } else if (key === TableKeyEnum.BLANK_SPOT) {
          return sortOrder === 'ASC' ? first.blankSpot - second.blankSpot : second.blankSpot - first.blankSpot;
        } else if (key === TableKeyEnum.TREE) {
          return sortOrder === 'ASC' ? first.tree - second.tree : second.tree - first.tree;
        }
        return 0;
      };

      const sortedDataRows = dataRows.sort((first, second) => sortFunction(first, second));

      const isBlankSpotExist = sortedDataRows.some((item) => item.blankSpot);
      const filteredDataRows = isBlankSpotExist
        ? sortedDataRows
        : (sortedDataRows.map(({ level, tree }) => ({ level, tree })) as IRow[]);

      const renderTableCell = (value: string | number) => {
        return <TableCellSummary>{value}</TableCellSummary>;
      };
      return filteredDataRows.map((row, i) => (
        <TableRow key={i}>
          <>
            {renderTableCell(row.level)}
            {!isNil(row?.blankSpot) && renderTableCell(row?.blankSpot)}
            {renderTableCell(row.tree)}
          </>
        </TableRow>
      ));
    }
    return <></>;
  }, [allLocationCropType, sortInfo]);

  // setup table label
  useEffect(() => {
    const optionsCell: Array<string> = [];
    isArray(allLocationCropType) &&
      allLocationCropType.forEach((item: any) => {
        item.standCountAnalysisSummary.forEach((jtem: any) => {
          optionsCell.push(jtem.title);
        });
      });

    const options = Array.from(new Set(optionsCell))
      .sort((a, b) => {
        return a.localeCompare(b);
      })
      .map((item) => ({
        key: item as TableKeyEnum,
        label: t(convertTextToMultiLanguage(item, 'mapView')),
        sortOrder: null,
      }));

    const newOptionsCols = [{ key: TableKeyEnum.LEVEL, label: t('trans.level'), sortOrder: null }, ...options];
    setOptionCols(newOptionsCols);
  }, [allLocationCropType, t]);

  const renderSortIcon = (sortOrder: TSortOrder) => {
    switch (sortOrder) {
      case 'ASC':
        return DescIcon;
      case 'DESC':
        return AscIcon;
      default:
        return SwapVertIcon;
    }
  };

  const handleSortTable = (key: TableKeyEnum, sortOrder: TSortOrder) => {
    let newSortOrder: TSortOrder = null;
    if (!sortOrder) newSortOrder = 'DESC';
    if (sortOrder === 'ASC') newSortOrder = 'DESC';
    if (sortOrder === 'DESC') newSortOrder = 'ASC';
    setSortInfo({ key, sortOrder: newSortOrder });

    const newOptionsCols = optionCols.map((col) =>
      col.key === key ? { ...col, sortOrder: newSortOrder } : { ...col, sortOrder: null }
    );
    setOptionCols(newOptionsCols);
  };

  return (
    <>
      <TableContainer
        sx={{
          overflow: 'hidden',
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '5px',
          mt: '-1px',
          mb: '12px',
        }}>
        <Table>
          <TableHead>
            {
              <TableRow
                component="tr"
                sx={{
                  backgroundColor: (theme) => theme.palette.background.paper,
                }}>
                {optionCols.map((col, index: number) => (
                  <TableCellHeaderSummary>
                    {col.label}
                    <SvgIcon
                      component={renderSortIcon(col.sortOrder)}
                      inheritViewBox
                      sx={{ fontSize: '14px', cursor: 'pointer' }}
                      onClick={() => handleSortTable(col.key, col.sortOrder)}
                    />
                  </TableCellHeaderSummary>
                ))}
              </TableRow>
            }
          </TableHead>
          <TableBody
            sx={{
              pt: 0,
              flexDirection: 'column',
              minHeight: '510px',
              maxHeight: '510px',
              overflowY: 'scroll',
              '-ms-overflow-style': 'none' /* IE and Edge */,
              scrollbarWidth: 'none' /* Firefox */,
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}>
            {tableCropCoverage}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          overflow: 'hidden',
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '5px',
          mt: '-1px',
          mb: '12px',
        }}>
        {isShowExtendIcon && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', height: '0px' }}>
            <Box
              sx={{
                top: '20px',
                right: '35px',
                position: 'relative',
                zIndex: 100,
              }}>
              <FullscreenOutlinedIcon
                sx={{
                  cursor: 'pointer',
                  color: theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B',
                }}
                onClick={() => setIsShowExtendChart(true)}
              />
            </Box>
          </Box>
        )}
        <ReactECharts option={option} theme={theme.palette.mode === 'dark' ? 'dark' : 'light'} />
      </Box>
      <StandExtendChartModal
        isShow={isShowExtendChart}
        handleClose={() => setIsShowExtendChart(false)}
        extendChartOption={extendChartOption}
      />
    </>
  );
};

export default StandCountTable;
